import React from "react"
import { Link } from "gatsby"
import { CSSTransition } from 'react-transition-group';
import MegaMenuLinks from "./mega-menu-links";
import Logo from '../_images/campaign-logo-horiz-with-tag-white.svg';
import Emblem from '../_images/campaign-emblem.svg';

import NewSite from '../_images/icons/white/new-window.svg';
import NewSiteBlue from '../_images/icons/blue/new-window.svg';

import AirwavesImage from '../_images/profiles/eric-resendiz-social.jpg';
import CosmosImage from '../_images/profiles/cosmos-social.jpg';
// import ExpressionImage from '../_images/profiles/teresita-de-la-torre-social.jpg';
import FrontlineImage from '../_images/profiles/frontline-social.jpg';
import PurposeImage from '../_images/profiles/opportunity-social.jpg';
// import RescueImage from '../_images/profiles/bryan-ruef-social.jpg';
import HopeImage from '../_images/profiles/mir-aminy-social.jpg';
import ResilienceImage from '../_images/profiles/kimberly-hardaway-social.jpg';
// import TasselImage from '../_images/profiles/tassel-social.jpg';
import TidesImage from '../_images/profiles/evelyn-bond-social.jpg';
import TradeImage from '../_images/profiles/trade-social2.jpg';
import TwitchImage from '../_images/profiles/twitch-social.jpg';
import WindsImage from '../_images/profiles/winds-social.jpg';
import ZoomImage from '../_images/profiles/zoom-social.jpg';
import titansOfResilience from '../_images/Titans-of-Resilience.jpg';
import titansOfEmpowerment from '../_images/Titans-of-Empowerment.jpg'


export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      hamburgerActive: false,
      scroll: false,
      scrolly: 0,
      width: 0,
      TitansOfMenuActive: false,
      MobileMenuActive: false
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.givingNav = React.createRef();
    this.givingButton = React.createRef();
    this.TitansOfMenu = React.createRef();
    this.TitansOfButton = React.createRef();
    this.menuButton = React.createRef();
    this.mainNav = React.createRef();
  }

  toggleMenu = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });

    if (this.state.active) {
      this.givingNav.current.focus();
    }

    const currentHamburgerState = this.state.hamburgerActive;
    this.setState({ hamburgerActive: !currentHamburgerState });
  }

  toggleMobileMenu = () => {
    const currentMobileState = this.state.MobileMenuActive;
    this.setState({ MobileMenuActive: !currentMobileState });

    // if (this.state.MobileMenuActive) {
    //   this.mainNav.current.focus();
    // }
  }

  toggleTitansOfMenu = () => {
    const currentState = this.state.TitansOfMenuActive;
    this.setState({ TitansOfMenuActive: !currentState });

    if (this.state.TitansOfMenuActive) {
      this.TitansOfMenu.current.focus();
    }
    
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleScroll(event) {
    this.setState({scrolly: window.pageYOffset});
    if (this.state.scrolly > 400) {
      this.setState({scroll: true});
    } else {
      this.setState({scroll: false});
    }
  }

  render() {
    return (
      <React.Fragment>
      <header id="header" className={this.state.scroll ? 'floating' : ''}>
        <Link activeClassName="active" to="/">
          <img src={Logo} alt="It Takes a Titan" id="logo" />
        </Link>
        <button onClick={this.toggleMobileMenu} id="mobile-menu-toggle" className={this.state.MobileMenuActive ? 'hamburger hamburger--elastic is-active': 'hamburger hamburger--squeeze'} type="button" ref={this.menuButton}>
          <span className="hamburger-box-wrapper">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </span>
          <span className="hamburger-text">Menu</span>
        </button>
        <nav id="main_nav" ref={this.mainNav} className={this.state.MobileMenuActive ? 'open': 'closed'}>
        <ul>
          {/* <li><a href="http://fullerton.edu/">CSUF</a></li> */}
          <li><Link activeClassName="active" to="/about">About</Link></li>
          <li><Link activeClassName="active" to="/impact">Impact Stories</Link></li>
          <li><Link activeClassName="active" to="/why-i-give">Why I Give</Link></li>
          <li>
            <button onClick={this.toggleTitansOfMenu} id="titansOfToggle" type="button" ref={this.TitansOfButton}>
              Titans Of
            </button>
          </li>
          <li>
            <button onClick={this.toggleMenu} id="hamburger" className={this.state.hamburgerActive ? 'hamburger hamburger--elastic is-active': 'hamburger hamburger--squeeze'} type="button" ref={this.givingButton}>
              <span className="hamburger-box-wrapper">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </span>
              <span className="hamburger-text">Ways to Give</span>
            </button>
          </li>
          <li><a href="http://give.fullerton.edu/91610" className="button pill orange"><strong>Give Now</strong></a></li>
        </ul>
        </nav>
      </header>
      <CSSTransition
      timeout={300}
      in={this.state.active}
      classNames={{
        enter: "animated",
        enterActive: "animated bounceInRight open",
        enterDone: 'animated bounceInRight open',
        exit: 'animated bounceOutRight open',
        exitActive: 'animated bounceOutRight open',
        exitDone: 'animated bounceOutRight open'
      }}
      >
        <nav id="giving_nav" tabIndex="-1" ref={this.givingNav}>
          <h2>There are many ways to give</h2>
          <ul>
             <li>
              <span className="header">By Investment Priority</span>
              <ul id="themeMenu">
                <MegaMenuLinks posttype="theme" />
                <li className="full-list">
                  <Link to="/investment-opportunities">Full List of Campaign Investment Opportunities</Link>
                </li>
              </ul>
            </li>
            <li>
              <span className="header">By College and Unit</span>
              <ul id="collegeMenu"><MegaMenuLinks posttype="college" /></ul>
            </li>
            <li className="general">
              <span className="or">&mdash;&nbsp;&nbsp;or&nbsp;&nbsp;&mdash; </span>
              <a href="http://give.fullerton.edu/91610" className="buttonBlue">Donate to Titan Fund <img src={NewSite} alt="Opens new website" /></a>
              <a href="https://give.fullerton.edu/search" className="buttonBlue outline">Search for a Fund <img src={NewSiteBlue} alt="Opens new website" /></a>
            </li>
            <li className="general">
              <a href="https://csufplannedgift.org/" className="buttonBlue outline">Planned Giving <img src={NewSiteBlue} alt="Opens new website" /></a>
              <Link style={{textAlign: 'center'}} to="/contact" className="buttonBlue outline">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </CSSTransition>
      <CSSTransition
      timeout={300}
      in={this.state.TitansOfMenuActive}
      classNames={{
        enter: "animated",
        enterActive: "animated bounceInRight open",
        enterDone: 'animated bounceInRight open',
        exit: 'animated bounceOutRight open',
        exitActive: 'animated bounceOutRight open',
        exitDone: 'animated bounceOutRight open'
      }}
      >
        <nav id="TitansOfMenu" tabIndex="-1" ref={this.TitansOfMenu}>
          <ul>
            <li><Link to="/empowerment/"><img src={titansOfEmpowerment} alt="" />Titans of Empowerment</Link></li>
            <li><Link to="/resilience/"><img src={titansOfResilience} alt="" />Titans of Resilience</Link></li>
            <li><Link to="/justice/"><img src={PurposeImage} alt="" />Titan of Justice</Link></li>
            {/* <li><Link to="/airwaves/"><img src={AirwavesImage} alt="" />Titan of the Airwaves</Link></li> */}
            <li><Link to="/cosmos/"><img src={CosmosImage} alt="" />Titans of the Cosmos</Link></li>
            {/* <li><Link to="/expression/"><img src={ExpressionImage} alt="" />Titan of Expression</Link></li> */}
            <li><Link to="/frontline/"><img src={FrontlineImage} alt="" />Titans of the Frontline</Link></li>
            {/* <li><Link to="/purpose/"><img src={PurposeImage} alt="" />Titan of Purpose</Link></li> */}
            {/* <li><Link to="/rescue/"><img src={RescueImage} alt="" />Titan of Rescue</Link></li> */}
            <li><Link to="/hope/"><img src={HopeImage} alt="" />Titan of Hope</Link></li>
            {/* <li><Link to="/tassel/"><img src={TasselImage} alt="" />Titans of Tassel</Link></li> */}
            <li><Link to="/tides/"><img src={TidesImage} alt="" />Titan of the Tides</Link></li>
            <li><Link to="/trade/"><img src={TradeImage} alt="" />Titans of Trade</Link></li>
            <li><Link to="/twitch/"><img src={TwitchImage} alt="" />Titans of Twitch</Link></li>
            <li><Link to="/winds/"><img src={WindsImage} alt="" />Titan of the Winds</Link></li>
            <li><Link to="/zoom/"><img src={ZoomImage} alt="" />Titans of Zoom</Link></li>
          </ul>
        </nav>
      </CSSTransition>
      </React.Fragment>
    );
  }
}