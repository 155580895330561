import React from "react"
import logo from '../_images/csuf-logo-footer.png'

export default (props) => (
  <footer id="site-footer">
    <div className="footer-top-bar"><span className="footer-orange-span"></span></div>
    <div className="wrap">

      <h2 className="hidden">Site Footer</h2>

      <div className="row">
        <div className="col-3">

          <a href="http://fullerton.edu" className="csuf-logo m-b-15 display-block"><img src="https://uawebstg.fullerton.edu/prototypes/campus-template-documentation/_assets/images/csuf-wordmark-reversed.svg" alt="Cal State Fullerton" className="display-block" /></a>

          <p className="m-b-10">800 N. State College Blvd.<br />Fullerton, CA 92831-3599</p>

          <p className="m-b-10">657-278-2011</p>

          {/* <!-- <ul className="social-list width-50">
            <li><a href="#" className="link-minimal"><img src="./_assets/images/facebook.svg" alt="Facebook" /></a></li>
            <li><a href="#" className="link-minimal"><img src="./_assets/images/twitter.svg" alt="Twitter" /></a></li>
            <li><a href="#" className="link-minimal"><img src="./_assets/images/instagram.svg" alt="Instagram" /></a></li>
            <li><a href="#" className="link-minimal"><img src="./_assets/images/linkedin.svg" alt="LinkedIn" /></a></li>
            <li><a href="#" className="link-minimal"><img src="./_assets/images/youtube.svg" alt="YouTube" /></a></li>
          </ul> --> */}

          
        </div>
        <div className="col-9">
          <p>This site is maintained by University Advancement. To report problems or comments with this site, please contact <a href="mailto:stratcomm@fullerton.edu" className="link-minimal">stratcomm@fullerton.edu</a>.</p>

          <h3>Web Accessibility</h3>
          <p>CSUF is committed to ensuring equal accessibility to our users. Let us know about any accessibility problems you encounter using this website. We’ll do our best to improve things and get you the information you need.</p>

          <p>Resources: <a href="https://get.adobe.com/reader/" className="link-minimal">Adobe Reader</a>&nbsp;&nbsp;&nbsp;<a href="https://www.microsoft.com/en-us/download/office.aspx" className="link-minimal">Microsoft Viewers</a>&nbsp;&nbsp;&nbsp;<a href="https://www.fullerton.edu/ati/reportati/" className="link-minimal">Report An ATI Issue</a>&nbsp;&nbsp;&nbsp;<a href="https://www.fullerton.edu/ati/" className="link-minimal">Accessible @ CSUF</a></p>
        </div>
        {/* <!-- <div className="col-4">
          <h3>Colleges</h3>

          <ul>
            <li><a className="link-minimal" href="http://www.fullerton.edu/arts/">The Arts</a></li>
            <li><a className="link-minimal" href="http://business.fullerton.edu">Business and Economics</a></li>
            <li><a className="link-minimal" href="http://communications.fullerton.edu">Communications</a></li>
            <li><a className="link-minimal" href="http://ed.fullerton.edu">Education</a></li>
            <li><a className="link-minimal" href="http://www.fullerton.edu/ecs">Engineering and Computer Science</a></li>
            <li><a className="link-minimal" href="http://hhd.fullerton.edu">Health and Human Development</a></li>
            <li><a className="link-minimal" href="http://hss.fullerton.edu">Humanities and Social Sciences</a></li>
            <li><a className="link-minimal" href="http://nsm.fullerton.edu">Natural Sciences and Mathematics</a></li>
            <li><a className="link-minimal" href="http://extension.fullerton.edu/">Extension and International Programs</a></li>
          </ul>

        </div>
        <div className="col-4">
          <h3>Resources</h3>

          <ul>
            <li><a className="link-minimal" href="#">University Police</a></li>
            <li><a className="link-minimal" href="#">Emergency Information</a></li>
            <li><a className="link-minimal" href="#">Annual Security Report (Clery)</a></li>
            <li><a className="link-minimal" href="#">California Campus Safety Plan</a></li>
            <li><a className="link-minimal" href="#">Title IX</a></li>
            <li><a className="link-minimal" href="#">Site Index</a></li>
            <li><a className="link-minimal" href="#">Campus Map</a></li>
            <li><a className="link-minimal" href="#">CSU</a></li>
            <li><a className="link-minimal" href="#">Accessibility</a></li>
            <li><a className="link-minimal" href="#">Contact Us</a></li>
          </ul>

        </div> --> */}
      </div>
    </div>
    <div className="footer-copyright p-15">
      <div className="wrap text-center text-size-xs">
        <p className="m-0">©<span id="copyright-year">2022</span> California State University, Fullerton. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
);