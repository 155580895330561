import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby"

export default (props) => (
  <StaticQuery
    query={graphql`
      query Links {
        allMarkdownRemark(sort: {order: ASC, fields: frontmatter___title2}) {
          nodes {
            frontmatter {
              title
              title2
              path
              posttype
            }
          }
        }
      }
    `}
    render={data => {
      
      const pages = data.allMarkdownRemark.nodes;

      // console.log(pages);

      var linklist = pages.map(function(page){
        if (props.posttype === page.frontmatter.posttype) {

          // if (page.frontmatter.posttype === 'theme') {

          //   return <li style={{backgroundImage: `url(${page.frontmatter.featuredImageHorizontal.childImageSharp.fluid.src})` }}><Link to={page.frontmatter.path}>{page.frontmatter.title}</Link></li>

          // } else {

          if (page.frontmatter.title2) {
            return <li key={page.frontmatter.path}><Link to={page.frontmatter.path}>{page.frontmatter.title2}</Link></li>
          } else {
            return <li key={page.frontmatter.path}><Link to={page.frontmatter.path}>{page.frontmatter.title}</Link></li>
          }

            

          // }
          
        }
      })

      return (
        linklist
      )
      
    }}
  />
)