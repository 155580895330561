import '../_styles/base.scss';
import React from "react"
import Header from "./header"
import Footer from "./footer"
import Metadata from './sitemetadata';

export default (props) => {
  var LocationInfo = '';
  if (props.locationInfo) {
    LocationInfo = props.locationInfo.pathname;
  } else {
    LocationInfo = null;
  }
  return (
    <React.Fragment>
      <Metadata locationInfo={LocationInfo} />
      <noscript style={{backgroundColor:'white', color: 'black'}}>Some page features may require javascript to run.</noscript>
      <div id="skipnav">
        <a href="#MainContent">Skip to Content (Press Enter)</a>
      </div>
      <Header />
      <main id="MainContent">
        {props.children}
      </main>
      <Footer />
    </React.Fragment>
  )
}
// export default ({children,props}) => (
//   console.log();
//   <React.Fragment>
//     <Metadata />
//     <noscript>Some page features may require javascript to run.</noscript>
//     <div id="skipnav">
//       <a href="#MainContent">Skip to Content (Press Enter)</a>
//     </div>
//     <Header />
//     <main id="MainContent">
//       {children}
//     </main>
//     <Footer />
//   </React.Fragment>
// )